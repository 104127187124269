import React, { useContext } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import { GatsbyImage } from "gatsby-plugin-image"

import "lazysizes"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { Link, graphql, navigate } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import Pagination from "material-ui-flat-pagination"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import LangContext from "../contexts/LangContext"
import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"

const useStyles = makeStyles(theme => ({
  date: {
    color: theme.palette.gray,
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  imageDiv: {
    display: "block",
    height: 200,

  },
  media: {
    height: 200,
  },
}))

const NewsPage = props => {
  const {
    data: { newsPage, newsItems },
    pageContext: { slug, locale, total, skip, limit, breadcrumbs },
  } = props

  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const items = newsItems.edges.map(r => r.node)

  const schema = newsPage.data.schema_json.text || ""
  const ogp = newsPage.data.ogp_tags || ""
  const meta = {
    title: newsPage.data.meta_title,
    description: newsPage.data.meta_description,
    keywords: newsPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />

      <Container maxWidth="lg">
        <main>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {newsPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>
            {newsPage.data.boxes.map(box => (
              <Grid item xs={12} md={4} key={shortid.generate()}>
                <Card
                  className={classes.card}
                  elevation={1}
                  square
                  variant="outlined"
                >
                  <CardActionArea
                    onClick={() => {
                      navigate(`${localizedSlugPrefix(newsPage)}/${box.link}`)
                    }}
                  >
                    <CardMedia>
                      <GatsbyImage
                        className={classes.media}
                        image={box.image.gatsbyImageData}
                        alt={box.box_title}
                      />
                    </CardMedia>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        {box.box_title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
            {items.map(item => (
              <Grid item key={item.id} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <Link
                      to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                    >
                      {item.data.image && item.data.image.gatsbyImageData && (
                        <GatsbyImage
                          className={classes.imageDiv}
                          image={item.data.image.gatsbyImageData}
                          alt={item.data.title.text}
                        />
                      )}
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Typography
                      component="div"
                      variant="subtitle1"
                    >
                      {`${format(parseISO(item.data.date), "PPP", {
                        locale: ro,
                      })} `}
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                    >
                      <Link
                        to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                      >
                        {item.data.title.text}
                      </Link>
                    </Typography>

                    <Typography component="p">
                      {item.data.excerpt.text}
                    </Typography>
                    <br />
                    <Link
                      variant="body2"
                      color="textPrimary"
                      to={`${localizedSlugPrefix(item)}/${slug}/${item.uid}/`}
                    >
                      {i18n.translations.continueReading}
                    </Link>
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </Grid>
            ))}

            <Grid item>
              <Pagination
                limit={limit}
                offset={skip}
                total={total}
                renderButton={({ offset, children }) => (
                  <Link
                    to={`${localizedSlugPrefix(newsPage)}/${slug}/${offset < limit ? "" : `${offset / limit + 1}/`
                      }`}
                  >
                    {children}
                  </Link>
                )}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
          />
        </main>
      </Container>
    </>
  )
}

export default NewsPage

export const query = graphql`
  query NewsPageQuery(
    $slug: String!
    $skip: Int!
    $limit: Int!
    $locale: String!
  ) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    newsPage: prismicNewsPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      data {
        title
        boxes {
          box_title
          link
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
      lang
    }

    newsItems: allPrismicNews(
      skip: $skip
      limit: $limit
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          data {
            excerpt {
              text
            }
            title {
              text
            }
            image {
              gatsbyImageData(layout: CONSTRAINED, height: 200)
            }
            date
          }

          uid
          lang
        }
      }
    }
  }
`

NewsPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    newsPage: PropTypes.object.isRequired,
    newsItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
}
